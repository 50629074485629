<template>
  <div>
    <script type="application/ld+json">
      {
        "@context": "http://schema.org",
        "@graph": [
          {
            "logo": "https://www.teddi.co/assets/socials/fb-profile.png",
            "name": "Teddi",
            "sameAs": ["https://www.facebook.com/www.teddi.co/"],
            "url": "https://www.teddi.co/",
            "@id": "#organization",
            "@type": ["Organization", "WebSite"]
          }
        ]
      }
    </script>
    <!-- Masthead -->
    <header class="masthead text-center text-md-left">
      <div class="container">
        <div class="row mt-md-5">
          <div class="col-11 col-md-6 mx-auto mx-md-0 px-md-0">
            <h1 class="mt-5">Recevez les annonces immobilières en premier</h1>
            <h5>
              Teddi analyse
              <strong class="text-blue">sans interruption</strong>
              les annonces immobilières publiées sur internet et vous envoie
              <strong class="text-blue">en temps réel</strong>
              celles qui correspondent à
              <strong class="text-blue">votre recherche</strong>.
            </h5>
            <b-btn to="/register" class="text-uppercase px-4 py-3 my-3">Essayer maintenant</b-btn>
            <ul class="features-list pl-0 d-block d-md-flex mt-2 mt-md-3">
              <li>
                <div>
                  <IconSubmit />
                </div>
                <div>Sans engagement</div>
              </li>
              <li>
                <div>
                  <IconSubmit />
                </div>
                <div>Essai gratuit</div>
              </li>
            </ul>
            <div class="d-flex align-items-center justify-content-center justify-content-md-start">
              <h5 class="mb-0 mr-2 text-blue">4.6 +</h5>
              <b-img
                class="img-fluid mr-2"
                alt="stars"
                :src="require('@/assets/img/testimonials-stars.svg')"
              ></b-img>
              <b-img
                class="img-fluid"
                alt="google"
                :src="require('@/assets/logo/google.svg')"
              ></b-img>
            </div>
          </div>
          <div class="col-md-3 offset-md-2 mt-md-0">
            <video autoplay="autoplay" loop="loop" muted="muted">
              <source :src="require('@/assets/video/animation.mp4')" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div class="row numbers">
          <div class="col-md-4">
            <h3>50,000 +</h3>
            <p>Nouvelles annonces / jour</p>
          </div>
          <div class="col-md-4">
            <h3>800 +</h3>
            <p>Sites analysés en temps réel</p>
          </div>
          <div class="col-md-4">
            <h3>10,000 +</h3>
            <p>Villes recensées</p>
          </div>
        </div>
      </div>
      <div class="overflow-hidden">
        <div class="skyline">
          <Skyline />
        </div>
      </div>
    </header>
    <!-- Features -->
    <section class="features">
      <div class="container">
        <div class="row row-equal">
          <div class="col-xl-auto mx-auto">
            <h2>
              <strong class="text-blue animation-pulse">Simplifiez</strong> votre recherche
              immobilière
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <b-img
              class="icon-content mb-2 bottom-negative"
              alt="teddi-time"
              :src="require('@/assets/img/teddi-time.svg')"
            ></b-img>
            <div class="text-left mx-auto mb-md-3 card">
              <h3 class="text-blue">1 heure gagnée par jour</h3>
              <h5 class="mb-0">
                Pourquoi perdre son temps à configurer une notification sur chaque site immobilier
                quand
                <span class="text-blue text-bold">tout peut être réuni</span>
                au même endroit ?
              </h5>
            </div>
          </div>
          <div class="col-lg-6">
            <b-img
              class="icon-content mb-2 bottom-negative"
              alt="teddi-teacher"
              :src="require('@/assets/img/teddi-teacher.svg')"
            ></b-img>
            <div class="text-left mx-auto mb-md-3 card">
              <h3 class="text-blue">Toutes les annonces</h3>
              <h5 class="mb-0">
                Saviez-vous que
                <strong class="text-blue">20 %</strong>
                des annonces ne sont
                <strong class="text-blue">pas publiées</strong>
                sur les grands sites immobiliers ? Teddi travaille chaque jour pour aller aussi les
                <strong class="text-blue">dénicher</strong>
                afin que vous ne ratiez
                <strong class="text-blue">aucune annonce</strong>.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Showcase -->
    <section class="showcase overflow-hidden">
      <div class="container">
        <div class="row py-5">
          <div class="col-xl-auto mx-auto">
            <h2>
              Elle devient
              <strong class="text-blue animation-pulse">intelligente</strong>
            </h2>
          </div>
        </div>
      </div>
      <div class="container px-3 px-md-0 text-left">
        <div class="row justify-content-center align-items-center p-2">
          <div class="col-md-4">
            <h3>Teddi recherche à <strong class="text-blue">votre place</strong></h3>
            <h5>Sur tous les sites immobiliers</h5>
            <p>
              Géants de l'immobilier, particuliers,
              <strong class="text-blue">petites agences</strong>, notaires,
              <strong class="text-blue">réseaux privés</strong>, réseaux sociaux...
            </p>
            <h5>Optimise les annonces</h5>
            <p>
              <strong class="text-blue">Simplification</strong>
              de l'annonce, mise en avant des
              <strong class="text-blue">informations clé</strong>, localisation plus précise,
              détection des <strong class="text-blue">doublons</strong>.
            </p>
          </div>
          <div class="col-md-4">
            <b-img
              class="icon-content d-block mx-auto"
              alt="teddi-hunter"
              :src="require('@/assets/img/teddi-hunter-color.svg')"
            ></b-img>
          </div>
        </div>
        <div class="row justify-content-center align-items-center p-2">
          <div class="col-md-4 order-2 order-md-1">
            <b-img
              class="icon-content d-block mx-auto"
              alt="teddi-mail"
              :src="require('@/assets/img/teddi-mail-color.svg')"
            ></b-img>
          </div>
          <div class="col-md-4 order-1 order-md-2">
            <h3>Et vous notifie <strong class="text-blue">dès leur publication</strong></h3>
            <h5>En temps réel</h5>
            <p>
              Idéal si vous êtes en
              <strong class="text-blue">recherche active</strong>
              : recevez les alertes dès leur parution.
            </p>
            <h5>Pour que la chance soit de votre côté</h5>
            <p>
              Les annonces immobilières les plus attractives sont retirées
              <strong class="text-blue">en moins de 3.2 heures</strong>
              en moyenne.
              <strong class="text-blue animation-pulse">Faites partie des premiers</strong>
              à les recevoir !
            </p>
          </div>
        </div>
      </div>
      <div class="col-xl-2 mx-auto mt-5">
        <b-btn to="/register" class="text-uppercase p-3 my-3">Essayer gratuitement</b-btn>
      </div>
    </section>
    <!-- Pricing -->
    <section class="pricing">
      <div class="container">
        <div class="row">
          <div class="col-md-7 mx-auto">
            <h2 class="py-5"><strong class="text-blue">7 jours</strong> d'essai gratuit</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 mx-auto">
            <div class="card">
              <h4><strong>Teddi</strong></h4>
              <h3 class="text-blue">19,90 € / mois</h3>
              <p class="text-blue mb-0">Essayez gratuitement pendant 7 jours</p>
              <hr />
              <ul>
                <li>Alertes illimitées en temps réel</li>
                <li>Dès la parution d'une nouvelle annonce</li>
                <li>+ de 800 sources analysées</li>
                <li>Recherche / prix au m²</li>
                <li>
                  Intégration WhatsApp
                  <span class="mx-1">
                    <b-img
                      class="icon-sm mr-2"
                      alt="whatsapp"
                      :src="require('@/assets/icons/whatsapp.svg')"
                    />
                    <b-badge class="bg-blue">BETA</b-badge></span
                  >
                </li>
              </ul>
              <b-btn to="/register" class="text-uppercase px-4 py-3">Essayer maintenant</b-btn>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Testimonials -->
    <section class="testimonials text-center">
      <div class="container">
        <h2 class="py-5">
          <strong><span class="text-blue">Nos utilisateurs</span></strong>
          en témoignent
        </h2>
        <div class="row">
          <div class="col-lg-4">
            <div class="mx-auto mb-5 mb-lg-0">
              <a
                rel="noopener"
                target="_blank"
                href="https://www.facebook.com/julie.gd.50/posts/10213107213170262:0"
              >
                <b-img
                  class="img-fluid"
                  alt="Nicolas"
                  :src="require('@/assets/img/testimonials-4.jpg')"
                ></b-img>
              </a>
              <h5>Julie</h5>
              <h6>A trouvé sa résidence principale avec Teddi</h6>
              <p class="mb-0">
                "Un vrai gain de temps par rapport aux multiples sites de recherche que l'on doit
                faire un par un !"
              </p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="mx-auto mb-5 mb-lg-0">
              <a
                rel="noopener"
                target="_blank"
                href="https://www.google.com/maps/contrib/110108853560375571023?hl=en-FR&sa=X&ved=2ahUKEwjD6vCWr6HmAhXdAWMBHbbcAbkQvvQBegQIARBR"
              >
                <b-img
                  class="img-fluid"
                  alt="Sylvain"
                  :src="require('@/assets/img/testimonials-5.jpg')"
                ></b-img>
              </a>

              <h5>Sylvain</h5>
              <h6>Investisseur</h6>
              <p class="mb-0">
                "Bonjour, j'ai découvert ce service par une amie et grâce à Teddi j'ai trouvé mon
                premier investissement grâce à Teddi. Je recommande !"
              </p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="mx-auto mb-5 mb-lg-0">
              <a
                rel="noopener"
                target="_blank"
                href="https://www.facebook.com/estelle.blandureau/posts/10216184802512171:0"
              >
                <b-img
                  class="img-fluid"
                  alt="Estelle"
                  :src="require('@/assets/img/testimonials-2.jpg')"
                ></b-img
              ></a>

              <h5>Estelle</h5>
              <h6>A trouvé sa location avec Teddi</h6>
              <p class="mb-0">
                "Super concept, un vrai e-mailing utile qui fait gagner un temps fou ! Très bon
                allié dans la jungle de l'immobilier où tout va très vite."
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="press">
      <div class="container">
        <!--  <div class="row d-flex flex-column align-items-center flex-md-row justify-content-center">
          <b-img alt="anouparis" :src="require('@/assets/img/press-anousparis.svg')"></b-img>
          <b-img alt="startupinfo" :src="require('@/assets/img/press-startupinfo.svg')"></b-img>
          <b-img alt="pepitestech" :src="require('@/assets/img/press-pepitestech.svg')"></b-img>
        </div> -->
        <div class="row justify-content-center text-center pt-5 px-3 px-md-0 pb-5 pb-md-0">
          <div class="col mx-auto bg-blue text-white text-left border-radius box-shadow">
            <div class="row py-5 justify-content-center align-items-center">
              <div class="col-md-6 d-flex justify-content-center text-center text-md-left my-3">
                <div>
                  <h4 class="text-bold">
                    "Une appli très utile. Je n’ai jamais visité autant d’appartements depuis que je
                    me suis inscrit il y a une semaine! Cela devrait se concrétiser très vite."
                  </h4>
                  <h6><i>Thomas. O</i></h6>
                </div>
              </div>
              <div class="col-md-3 d-flex justify-content-center">
                <div>
                  <b-btn
                    to="/register"
                    @click.native="$scrollToTop"
                    class="btn-white-unset text-bold text-uppercase px-4 py-3 my-3"
                    >Essayer maintenant</b-btn
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import IconPrice from "@/components/icons/IconPrice.vue";
import IconLocations from "@/components/icons/IconLocations.vue";
import IconPaperplane from "@/components/icons/IconPaperplane.vue";
import IconText from "@/components/icons/IconText.vue";
import IconCopyright from "@/components/icons/IconCopyright.vue";
import IconSubmit from "@/components/icons/IconSubmit.vue";
import Skyline from "@/components/content/Skyline.vue";
import Footer from "@/components/bottom/Footer.vue";

export default {
  metaInfo: {
    title: "Recevez les annonces immobilières en premier",
    titleTemplate: "%s ← Teddi",
    meta: [
      { "http-equiv": "Content-Type", content: "text/html; charset=utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content:
          "Teddi analyse sans interruption les annonces immobilières publiées sur internet et vous envoie en temps réel celles qui correspondent à votre recherche.",
      },
      {
        property: "og:title",
        content: "Recevez les annonces immobilières en premier ← Teddi",
      },
      { property: "og:site_name", content: "Teddi" },
      { property: "og:type", content: "website" },
      {
        property: "og:url",
        content: "https://www.teddi.co",
      },
      {
        property: "og:image",
        content: "https://www.teddi.co/assets/socials/fb-cover.jpg",
      },
      {
        property: "og:description",
        content:
          "Teddi analyse sans interruption les annonces immobilières publiées sur internet et vous envoie en temps réel celles qui correspondent à votre recherche.",
      },
    ],
    link: [{ rel: "canonical", href: "https://www.teddi.co" }],
  },
  components: {
    IconPrice,
    IconLocations,
    IconPaperplane,
    IconText,
    IconCopyright,
    IconSubmit,
    Footer,
    Skyline,
  },
  data() {
    return {
      slide: "expressions",
    };
  },

  methods: {
    showSlide(value) {
      this.slide = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/forms.scss";
@import "@/styles/cards.scss";
@import "@/styles/buttons.scss";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

section,
header {
  color: $landing-color;
  overflow-x: hidden;

  @include media-breakpoint-up(md) {
    padding-bottom: 5rem;
  }
}
hr {
  border-top: 2px solid rgba(255, 255, 255, 0.1);
}

video {
  width: 100%;
  @include media-breakpoint-up(md) {
    transform: scale(1.4);
    margin-top: 2.7rem;
  }
}

h1 {
  font-size: 2.2rem;
  @include media-breakpoint-up(md) {
    font-size: 3.2rem;
  }
  font-weight: bold;
  color: $blue;
}
h2 {
  font-size: 2.5rem;
}
h2,
h3 {
  font-weight: 600;
}
h5 {
  line-height: 1.8rem;
  font-weight: normal;
}
p {
  font-weight: normal;
}

.masthead {
  img:nth-of-type(1) {
    width: 5rem;
  }
  img:nth-of-type(2) {
    width: 4rem;
  }
}

.skyline {
  margin-bottom: 0;
  transform: scale(2);
  @include media-breakpoint-up(md) {
    margin-bottom: -9px;
    margin-top: 2rem;
    transform: scale(1);
  }
}

.features {
  @include media-breakpoint-down(md) {
    padding-top: 5rem;
  }
}

.card {
  padding: 2.5rem;
}

.numbers {
  text-align: center;
  margin-top: 2rem;
  @include media-breakpoint-up(md) {
    margin-top: 8rem;
  }
  h3 {
    color: $blue;
  }
}

.icon-content {
  width: 15rem;
}

.bottom-negative {
  bottom: -3rem;
  position: relative;
  z-index: 1;
}

.features-list {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  li {
    display: flex;
    align-items: center;
    @include media-breakpoint-up(md) {
      margin-right: 40px;
    }
  }
}

.showcase {
  .row {
    padding-bottom: 5rem;
  }
  h3 {
    margin-bottom: 1.7rem;
  }
  h5 {
    font-weight: 600;
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1rem;
    font-size: 1.1rem;
  }
}

.testimonials {
  img {
    border-radius: 50%;
    width: 10rem;
    box-shadow: $box-shadow;
    margin-bottom: 1.5rem;
  }
  h5,
  h6 {
    font-weight: 600;
  }
  h6 {
    color: $blue;
  }
}

.pricing {
  .card {
    text-align: left;
    padding: 2rem;
  }
  ul {
    padding-left: 0;
    li {
      margin-bottom: 1rem;
      display: flex;
      &:before {
        margin-right: 0.5rem;
        width: 1rem;
        padding: 0 4px 0 0;
        vertical-align: -50%;
        content: url('data:image/svg+xml; utf8, <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="%234185f4" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>');
      }
    }
  }
}

.press {
  img {
    width: 8rem;
    margin-bottom: 0.8rem;
    @include media-breakpoint-up(md) {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }
}

.mx-md-10 {
  @include media-breakpoint-up(md) {
    margin-left: 8rem;
    margin-right: 8rem;
  }
}
::v-deep {
  .features-list {
    li {
      flex-direction: column;
      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
      align-items: center;
      svg {
        width: 1.3rem;
        margin-right: 0.5rem;
        path {
          fill: $blue;
        }
      }
    }
  }
}
</style>
